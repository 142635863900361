import React from "react";
import { useLocation } from "react-router-dom";
import SuccessDocs from "../components/SuccessDocs";
import SuccessFooter from "../components/SuccessFooter";
import SuccessHeader from "../components/SuccessHeader";
import UserAvatar from "../components/UserAvatar";
import { PlanDuration, PlanType } from "../utils/config-types";
import { DiscordUser } from "../utils/models";
import { PaddleSubscriptionPlansConfig } from "../utils/plans";

interface LocationState {
  planDuration: PlanDuration;
  planType: PlanType;
  userInfo: DiscordUser;
}

const SupporterSuccess: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const planType: PlanType = (state?.planType as PlanType) || "supporter";
  const userInfo: DiscordUser = state?.userInfo || {
    username: "Unknown User",
    avatarHash: "",
    discriminator: "0000",
  };
  const planTypeConfig = PaddleSubscriptionPlansConfig[planType!];

  return (
    <div className="row g-0 justify-content-center my-10">
      <div className="col-md-6">
        <div className={`card ${planTypeConfig.borderClass}`}>
          <div className="card-body">
            <SuccessHeader planType={planType} />
            <UserAvatar userInfo={userInfo} />
            <p>
              You can now use all the <em>{planTypeConfig.title}</em> Reaction
              Roles features.
            </p>
            <p className="my-5">
              This includes use of the command,{" "}
              <code className="text-nowrap">/directmessages</code>, to
              enable/disable DMs sent from the Reaction Roles bot in any
              server.
            </p>
            <SuccessDocs />
          </div>
          <SuccessFooter />
        </div>
      </div>
    </div>
  );
};

export default SupporterSuccess;
