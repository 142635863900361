import React from "react";
import { DiscordUtil } from "../utils/discord-util";
import { DiscordUser } from "../utils/models";

interface UserAvatarProps {
  userInfo: DiscordUser;
}

function UserAvatar(props: UserAvatarProps) {
  return (
    <div className="d-flex align-items-center justify-content-center m-8">
      <img
        src={DiscordUtil.getAvatarUrl(props.userInfo)}
        alt=""
        width="32"
        height="32"
        className="rounded-circle me-2 float-start"
      />
      <h5>{props.userInfo.username}</h5>
    </div>
  );
}

export default UserAvatar;
