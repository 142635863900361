import React from "react";
import tickMark from "../img/tick-mark.svg";
import xMark from "../img/x-mark.svg";
import { PlanType } from "../utils/config-types";
import { PaddleSubscriptionPlansConfig } from "../utils/plans";

interface FeatureListProps {
  planType: PlanType;
}

const FeatureList: React.FC<FeatureListProps> = (props: FeatureListProps) => {
  const cfg = PaddleSubscriptionPlansConfig[props.planType];

  function mark(value: boolean) {
    let mark;
    if (value) {
      mark = tickMark;
    } else {
      mark = xMark;
    }
    return (
      <div>
        <img alt="" src={mark} width="20px" height="20px" />
      </div>
    );
  }

  return (
    <ul className="list-group list-group-flush">
      {cfg.features.map((feature: any) => (
        <li
          key={feature.title}
          className="list-group-item d-flex align-items-start px-0"
        >
          {mark(feature.allowed)}
          <div className="ms-1">
            <span className="h5">{feature.title}</span>
            {feature.desc && (
              <span className="ms-auto text-white-50">
                <br />
                {feature.desc}
              </span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FeatureList;
