import React from "react";

const UspSupporter: React.FC = () => {
  return (
    <div className="row mt-5 mb-10">
      <div className="col text-center text-light">
        <h1 className="display-6">Become a Droplet and <strong>Reaction Roles</strong> <em>Supporter</em></h1>
        <h2>
          Unlock <em>Awesome</em> Features and Support Our Future Development
        </h2>
      </div>
    </div>
  );
};

export default UspSupporter;
