import React from "react";
import Button from "react-bootstrap/Button";
import { STATIC_CONFIG } from "../utils/config";

function SuccessDocs() {
  return (
    <p className="mb-0">
      You can view all the Reaction Roles documentation on-line :{" "}
      <Button variant="primary" href={STATIC_CONFIG.DOCS_PREMIUM_URL}>
        Reaction Roles Documentation
      </Button>
    </p>
  );
}

export default SuccessDocs;
