import { ENV_MAP, HOST_MAP } from "./config-maps";
import { AppEnvironmentType } from "./config-types";

export const APP_ENV: string = getEnv();
export const APP_CONFIG: AppEnvironmentType = ENV_MAP[APP_ENV];
export const APP_API_BASE = APP_CONFIG.apiBase;

function getEnv(): string {
  const host = window.location.hostname;
  let env = "local";
  Object.keys(HOST_MAP).forEach((key) => {
    if (HOST_MAP[key].indexOf(host) > -1) {
      env = key;
    }
  });
  return env;
}

export const STATIC_CONFIG = {
  DOCS_PREMIUM_URL: "https://droplet.gg/docs/",
  DISCORD_INVITE_URL: "https://discord.gg/fjBme5HW7F",
  FREE_INVITE_URL: "https://go.droplet.gg/invite/free",
  SITE_HOME: "https://www.droplet.gg/",
  SITE_PREMIUM: "https://www.droplet.gg/premium",
  SITE_PRIVACY: "https://www.droplet.gg/privacy-policy",
  SITE_TERMS: "https://www.droplet.gg/terms-of-service",
};
