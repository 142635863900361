import { APP_API_BASE } from "./config";
import { PlanDuration, PlanType } from "./config-types";
import { DiscordUser } from "./models";

export class DiscordUtil {
  static login(planDuration: PlanDuration, planType: PlanType) {
    // Start the oauth flow for Discord. The deeplink param is planDuration/planType so that when we come back
    // from Discord, we can open the correct plan and duration on the Plan page.
    //
    // @ts-ignore
    // Without the 'href' property sends it to the origin you specify here.
    window.location =
      APP_API_BASE +
      "/api/member/auth/start?deeplink=" +
      planDuration +
      "/" +
      planType;
  }

  static getAvatarUrl(userInfo: DiscordUser) {
    if (userInfo.avatarHash) {
      return `https://cdn.discordapp.com/avatars/${userInfo.discordUserId}/${userInfo.avatarHash}.png?size=128`;
    }
    return `https://cdn.discordapp.com/embed/avatars/${
      userInfo.discriminator % 5
    }.png`;
  }

  // static getGuildIconUrl(guild: DiscordGuild) {
  //   if (guild.icon) {
  //     return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png?size=128`;
  //   }
  //   return `https://ui-avatars.com/api/?rounded=true&format=svg&size=128&font-size=0.75&length=1&name=${guild.name}`;
  // }
}
