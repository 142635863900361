import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import FeatureList from "../components/FeatureList";
import PlanHeader from "../components/PlanHeader";
import UserAvatar from "../components/UserAvatar";
import UspSupporter from "../components/UspSupporter";
import { useUserQuery } from "../hooks/useMemberServiceApi";
import { APP_ENV } from "../utils/config";
import { PlanDuration, PlanType } from "../utils/config-types";
import { DiscordUtil } from "../utils/discord-util";
import { PaddlePassthrough, PaddleUtil } from "../utils/paddle-util";
import { PaddleSubscriptionPlans } from "../utils/plans";

const Supporter: React.FC = () => {
  const { sessionId } = useParams();

  const planType: PlanType = "supporter";
  const planDuration: PlanDuration = "yearly";
  const planInfo = PaddleSubscriptionPlans[planType!][planDuration!][APP_ENV];
  const navigate = useNavigate();

  function login() {
    DiscordUtil.login(planDuration, planType);
  }

  function onGetPlan() {
    let passthrough: PaddlePassthrough = {
      discordGuildId: null,
      userId: userInfo!.userId,
    };
    console.log(
      `get the ${planDuration} plan, ${planType}, for '${
        userInfo!.username
      }' (${userInfo!.discordUserId}). ${JSON.stringify(passthrough)}`
    );

    PaddleUtil.openCheckout(
      planInfo.planNumber,
      userInfo!.email,
      passthrough,
      navToSuccess
    );
  }

  function navToAllPlans() {
    navigate("/plans", {
      state: { planDuration: planDuration, sessionId: sessionId },
    });
  }

  function navToSuccess() {
    navigate("/success", {
      replace: true,
      state: {
        planDuration: planDuration,
        planType: planType,
        userInfo: userInfo,
      },
    });
  }

  const { isLoading, isError, data: userInfo } = useUserQuery(sessionId);

  if (isError) {
    return <p>Error!</p>;
  }
  if (isLoading) {
    return <p>Loading...</p>;
  }

  let button;
  if (sessionId && userInfo) {
    button = (
      <>
        <UserAvatar userInfo={userInfo} />
        <button
          onClick={onGetPlan}
          className="btn btn-success btn-lg fw-bolder fs-5 btn-hover-scale"
        >
          Become a <span className="fs-3">Supporter</span>
        </button>
      </>
    );
  } else {
    button = (
      <button
        onClick={login}
        className="btn btn-success btn-lg fw-bolder fs-5 btn-hover-scale"
      >
        Get started by <span className="fs-3">Logging in</span> with Discord
      </button>
    );
  }

  return (
    <>
      <UspSupporter />

      <div className="row g-4 justify-content-center mb-10">
        <div className="col-md-4">
          <div className="card border-success">
            <div className="card-body">
              <PlanHeader planType="supporter" planDuration="yearly" />
              <FeatureList planType="supporter" />
            </div>
            <div className="card-footer">
              <div className="d-grid">{button}</div>
            </div>
          </div>

          <div className="text-center mt-8">
            <button
              className="btn btn-sm btn-light fw-bolder fs-8 me-5"
              onClick={navToAllPlans}
            >
              View All Plans
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Supporter;
