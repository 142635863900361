import { useQuery } from "react-query";
import { APP_API_BASE } from "../utils/config";
import { DiscordGuilds, DiscordUser } from "../utils/models";

export function useGuildsQuery(sessionId: string | undefined) {
  async function loadGuilds(): Promise<DiscordGuilds> {
    //console.log("fetching guilds ... " + sessionId);
    const response = await fetch(APP_API_BASE + "/api/member/user/guilds", {
      headers: {
        "x-botsy-session": sessionId!,
      },
    });
    return await response.json();
  }

  return useQuery<DiscordGuilds>("guilds", loadGuilds, {
    enabled: !!sessionId,
  });
}

export function useUserQuery(sessionId: string | undefined) {
  async function loadUser(): Promise<DiscordUser> {
    //console.log("fetching user ... " + sessionId);
    const response = await fetch(APP_API_BASE + "/api/member/user/user", {
      headers: {
        "x-botsy-session": sessionId!,
      },
    });
    return await response.json();
  }

  return useQuery<DiscordUser>("user", loadUser, {
    enabled: !!sessionId,
  });
}
