import React from "react";
import { PlanDuration } from "../utils/config-types";

interface DurationSwitcherProps {
  planDuration: PlanDuration;
  onPlanDurationChange: (planDuration: PlanDuration) => void;
}

const DurationSwitcher: React.FC<DurationSwitcherProps> = (
  props: DurationSwitcherProps
) => {
  function getDurationClass(duration: PlanDuration) {
    if (props.planDuration === duration) {
      return "btn-info";
    }
    return "";
  }

  function onChangeDuration() {
    if (props.planDuration === "monthly") {
      props.onPlanDurationChange("yearly");
    } else {
      props.onPlanDurationChange("monthly");
    }
  }

  return (
    <div className="bg-light rounded-pill px-3 py-2 ">
      <button
        onClick={onChangeDuration}
        className={`btn btn-lg fw-bold py-3 px-5 m-1 rounded-pill ${getDurationClass(
          "monthly"
        )}`}
      >
        <span className="h4">Monthly</span>
      </button>

      <button
        onClick={onChangeDuration}
        className={`btn btn-lg fw-bold py-3 px-5 m-1 rounded-pill ${getDurationClass(
          "yearly"
        )}`}
      >
        <span className="h4">Yearly</span>
      </button>
    </div>
  );
};

export default DurationSwitcher;
