import React from "react";

const UspPremium: React.FC = () => {
  return (
    <div className="row mt-5 mb-10">
      <div className="col text-center text-light">
        <h1 className="display-6">Get <strong>Reaction Roles</strong> <em>Premium</em></h1>
        <h2>
          Unlock <em>Awesome</em> Features and Support Our Future Development
        </h2>
      </div>
    </div>
  );
};

export default UspPremium;
