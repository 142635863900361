import React from "react";
import { APP_ENV } from "../utils/config";
import { PlanDuration, PlanType } from "../utils/config-types";
import {
  PaddleSubscriptionPlansConfig,
  PaddleSubscriptionPlans,
} from "../utils/plans";

interface PlanHeaderProps {
  planDuration: PlanDuration;
  planType: PlanType;
}

function PlanHeader(props: PlanHeaderProps) {
  const planDurationConfig = PaddleSubscriptionPlansConfig[props.planDuration];
  const planTypeConfig = PaddleSubscriptionPlansConfig[props.planType];
  const planInfo =
    PaddleSubscriptionPlans[props.planType][props.planDuration][APP_ENV];

  return (
    <>
      <p className="h3 card-title text-center mb-5">{planTypeConfig.title}</p>
      <p className="display-5 card-title text-center mb-1">
        {planTypeConfig.costFunc(
          planInfo.planCost,
          planDurationConfig.simpleText
        )}
      </p>
      <p className="text-center text-white-50 mb-7">
        {planTypeConfig.costDescFunc(planDurationConfig.simpleText)}
      </p>
    </>
  );
}

export default PlanHeader;
