import React from "react";
import { useLocation } from "react-router-dom";
import SuccessDocs from "../components/SuccessDocs";
import SuccessFooter from "../components/SuccessFooter";
import SuccessHeader from "../components/SuccessHeader";
import { PlanType } from "../utils/config-types";
import { PaddleSubscriptionPlansConfig } from "../utils/plans";

interface LocationState {
  planType: string;
  serverName: string;
}

const Success: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const planType: PlanType = (state?.planType as PlanType) || "premiumPlus";
  const serverName = state?.serverName || "My Big Popular Server";
  const planTypeConfig = PaddleSubscriptionPlansConfig[planType!];

  return (
    <div className="row g-0 justify-content-center my-10">
      <div className="col-md-6">
        <div className={`card ${planTypeConfig.borderClass}`}>
          <div className="card-body">
            <SuccessHeader planType={planType} />
            <p>
              You can now use all the <em>{planTypeConfig.title}</em> Reaction
              Roles features in your server:
            </p>
            <p className="my-5 fs-3 text-center">{serverName}</p>
            <p className="my-5">
              To learn more about the Reaction Roles premium features, go to
              your Discord server and type the command,{" "}
              <code className="text-nowrap">/premium</code>.
            </p>
            <SuccessDocs />
          </div>
          <SuccessFooter />
        </div>
      </div>
    </div>
  );
};

export default Success;
