import React from "react";
import { STATIC_CONFIG } from "../utils/config";

function SuccessFooter() {
  return (
    <div className="card-footer">
      <p>
        Stay up-to-date on everything happening with Reaction Roles by joining
        our friendly Discord community in the{" "}
        <a href={STATIC_CONFIG.DISCORD_INVITE_URL}>
          Droplet Development server.
        </a>
      </p>
    </div>
  );
}

export default SuccessFooter;
