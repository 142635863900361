interface IPaddle {
  Checkout: ICheckout;
}

interface ICheckout {
  open(options: any): void;
}

export interface PaddlePassthrough {
  discordGuildId: string | null;
  userId: string;
}

// Docs: https://developer.paddle.com/classic/reference/5e0171ec215eb-checkout-parameters
export class PaddleUtil {
  static openCheckout(
    planNumber: string,
    email: string,
    passthrough: PaddlePassthrough,
    navToSuccess: () => void
  ) {
    const Paddle: IPaddle = (window as any).Paddle;
    //console.log("opening for plan: " + planNumber);
    Paddle.Checkout.open({
      displayModeTheme: "dark",
      marketingConsent: 0,
      allowQuantity: false,
      product: planNumber,
      email: email,
      passthrough: JSON.stringify(passthrough),
      successCallback: (data: any, err: any) => {
        //console.log("back from Paddle: ", data);
        navToSuccess();
      },
    });
  }
}
