import React, {Fragment} from 'react';

export const PaddleSubscriptionPlansConfig = {
  monthly: {
    planDuration: "monthly",
    simpleText: "month",
    officialText: "Monthly",
    borderClass: "border-primary",
    buttonClass: "btn-primary",
    otherDuration: {
      planDuration: "yearly",
      officialText: "Yearly",
    },
  },
  yearly: {
    planDuration: "yearly",
    simpleText: "year",
    officialText: "Yearly",
    borderClass: "border-sbot",
    buttonClass: "btn-sbot",
    otherDuration: {
      planDuration: "monthly",
      officialText: "Monthly",
    },
  },
  free: {
    title: "Free plan",
    costFunc: (cost: number, duration: string) => "FREE",
    costDescFunc: (duration: string) => "Invite the bot and use for free",
    features: [
      {allowed: true, title: "Reaction Roles", desc: "Use Reaction Roles on up to 10 messages with a max of 20 reaction roles per message (200 total)."},
      {allowed: false, title: "Reverse (Remove/Add) Reaction Roles", desc: (<Fragment>Use the <code>/types</code> command for info on all supported reaction role types.</Fragment>)},
      {allowed: true, title: "Auto Roles", desc: "Maximum 1 human and 1 bot role."},
      {allowed: false, title: "Grouped Roles"},
      {allowed: false, title: "Timed Roles"},
      {allowed: false, title: "Self-destructing Roles"},
      {allowed: false, title: "Ability to set role usage limits"},
      {allowed: false, title: "Ability to set role requirements"},
      {allowed: false, title: "Disable DMs to users when they react"},
      {allowed: false, title: "Log activity to a channel"},
      {allowed: false, title: "Create and edit custom embeds"},
    ],
    borderClass: "border-secondary",
    buttonClass: "btn-dark",
    otherType: "premium",
  },
  premium: {
    title: "Premium",
    costFunc: (cost: number, duration: string) =>
      `$${cost.toFixed(0)} / ${duration}`,
    costDescFunc: (duration: string) => `Billed every ${duration}`,
    features: [
      {allowed: true, title: "Reaction Roles", desc:"Use Reaction Roles on up to 40 messages with a max of 40 reaction roles per message (1600 total)."},
      {allowed: true, title: "Reverse (Remove/Add) Reaction Roles", desc: (<Fragment>Use the <code>/types</code> command for info on all supported reaction role types.</Fragment>)},
      {allowed: true, title: "Auto Roles", desc: "Maximum 5 human and 5 bot roles."},
      {allowed: true, title: "Grouped Roles", desc: "Use Grouped Roles on up to 10 messages with max 20 group roles per message."},
      {allowed: true, title: "Timed Roles", desc: "Use Timed Roles on up to 5 messages with max 10 timed roles per message. Maximum time is 30-days."},
      {allowed: true, title: "Self-destructing Roles", desc: "Maximum time is 30-days."},
      {allowed: true, title: "Ability to set role usage limits", desc: "Maximum limit is 1000 uses."},
      {allowed: true, title: "Ability to set role requirements", desc: "Maximum 5 role requirements per server."},
      {allowed: true, title: "Disable DMs to users when they react"},
      {allowed: true, title: "Log activity to a channel"},
      {allowed: true, title: "Create and edit custom embeds"},
    ],
    borderClass: "border-primary",
    buttonClass: "btn-primary",
    otherType: "premiumPlus",
  },
  premiumPlus: {
    title: "Premium Plus",
    costFunc: (cost: number, duration: string) =>
      `$${cost.toFixed(0)} / ${duration}`,
    costDescFunc: (duration: string) => `Billed every ${duration}`,
    features: [
      {allowed: true, title: "Reaction Roles", desc:"Use Reaction Roles on up to 500 messages with a max of 500 reaction roles per message  (250000 total)."},
      {allowed: true, title: "Reverse (Remove/Add) Reaction Roles", desc: (<Fragment>Use the <code>/types</code> command for info on all supported reaction role types.</Fragment>)},
      {allowed: true, title: "Auto Roles", desc:"Maximum 40 human and 40 bot roles."},
      {allowed: true, title: "Grouped Roles", desc: "Use Grouped Roles on up to 250 messages with max 50 group roles per message."},
      {allowed: true, title: "Timed Roles", desc: "Use Timed Roles on up to 40 messages with max 40 timed roles per message. Maximum time is 1-year."},
      {allowed: true, title: "Self-destructing Roles", desc: "Maximum time is 1-year."},
      {allowed: true, title: "Ability to set role usage limits", desc: "Maximum limit is 100000 uses."},
      {allowed: true, title: "Ability to set role requirements", desc: "Maximum 20 role requirements per server."},
      {allowed: true, title: "Disable DMs to users when they react"},
      {allowed: true, title: "Log activity to a channel"},
      {allowed: true, title: "Create and edit custom embeds"},
    ],
    borderClass: "border-sbot",
    buttonClass: "btn-sbot",
    otherType: "premium",
  },
  supporter: {
    title: "Supporter",
    costFunc: (cost: number, duration: string) =>
      `$${cost.toFixed(0)} / ${duration}`,
    costDescFunc: (duration: string) => `Billed every ${duration}`,
    features: [
      {allowed: true, title: "Disable DMs from the bot.", desc: "With 1 command, completely turn off DMs from the bot to you in every server that uses the Reaction Roles bot."},
      {allowed: true, title: "Supporter role in the Droplet Development server.", desc: "Show off your support and creds with a special role in the Droplet Development support server."},
      {allowed: true, title: "Support the future development of Droplet and Reaction Roles.", desc: "Help us continue operating and improving the Reaction Roles bot."},
    ],
    borderClass: "border-success",
    buttonClass: "btn-success",
    otherType: "supporter",
  }
};

const FreePlan: any = {
  monthly: {
    local: {
      planNumber: 0,
      planCost: 0,
      planCostOrig: 0,
    },
    prod: {
      planNumber: 0,
      planCost: 0,
      planCostOrig: 0,
    },
  },
  yearly: {
    local: {
      planNumber: 0,
      planCost: 0,
      planCostOrig: 0,
    },
    prod: {
      planNumber: 0,
      planCost: 0,
      planCostOrig: 0,
    },
  },
};

const PremiumPlan: any = {
  monthly: {
    local: {
      planNumber: 44035,
      planCost: 5,
      planCostOrig: 8,
    },
    prod: {
      planNumber: 809797,
      planCost: 5,
      planCostOrig: 5,
    },
  },
  yearly: {
    local: {
      planNumber: 44034,
      planCost: 50,
      planCostOrig: 20,
    },
    prod: {
      planNumber: 809796,
      planCost: 50,
      planCostOrig: 50,
    },
  },
};

const PremiumPlusPlan: any = {
  monthly: {
    local: {
      planNumber: 44035,
      planCost: 12,
      planCostOrig: 8,
    },
    prod: {
      planNumber: 858544,
      planCost: 12,
      planCostOrig: 6,
    },
  },
  yearly: {
    local: {
      planNumber: 44034,
      planCost: 100,
      planCostOrig: 20,
    },
    prod: {
      planNumber: 858545,
      planCost: 100,
      planCostOrig: 60,
    },
  },
};


const SupporterPlan: any = {
  monthly: {
    local: {
      planNumber: 65808,
      planCost: 19,
      planCostOrig: 19,
    },
    prod: {
      planNumber: 859663,
      planCost: 10,
      planCostOrig: 10,
    },
  },
  yearly: {
    local: {
      planNumber: 65808,
      planCost: 10,
      planCostOrig: 19,
    },
    prod: {
      planNumber: 859663,
      planCost: 10,
      planCostOrig: 10,
    },
  },
};

export const PaddleSubscriptionPlans: any = {
  free: FreePlan,
  premium: PremiumPlan,
  premiumPlus: PremiumPlusPlan,
  supporter: SupporterPlan,
};
