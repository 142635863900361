import React from "react";
import { PlanType } from "../utils/config-types";
import { PaddleSubscriptionPlansConfig } from "../utils/plans";

interface SuccessHeaderProps {
  planType: PlanType;
}

function SuccessHeader(props: SuccessHeaderProps) {
  const planTypeConfig = PaddleSubscriptionPlansConfig[props.planType!];

  let text;
  if (props.planType === "supporter") {
    text = (
      <>
        Thanks for becoming a Reaction Roles <em>{planTypeConfig.title}</em> !
      </>
    );
  } else {
    text = (
      <>
        Thanks for subscribing to Reaction Roles <em>{planTypeConfig.title}</em>{" "}
        !
      </>
    );
  }

  return (
    <h2 className="py-5 mb-5 text-center">
      &#9989;
      <br />
      {text}
      <br />
      &#128077;&#128077;&#128077;
    </h2>
  );
}

export default SuccessHeader;
