import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DurationSwitcher from "../components/DurationSwitcher";
import FeatureList from "../components/FeatureList";
import PlanHeader from "../components/PlanHeader";
import UspPremium from "../components/UspPremium";
import { STATIC_CONFIG } from "../utils/config";
import { PlanDuration, PlanType } from "../utils/config-types";
import { DiscordUtil } from "../utils/discord-util";

const Plans: React.FC = () => {
  const { state } = useLocation();
  const [planDuration, setPlanDuration] = useState<PlanDuration>(
    state?.planDuration || "monthly"
  );
  const navigate = useNavigate();

  //console.log("plans : sessionId=" + state?.sessionId);

  function onGetPlan(planType: PlanType) {
    switch (planType) {
      case "free":
        window.location.href = STATIC_CONFIG.FREE_INVITE_URL;
        break;
      case "premium":
      case "premiumPlus":
        if (state?.sessionId) {
          navigate(`/${planDuration}/${planType}/${state.sessionId}`);
        } else {
          // Start the oauth flow for Discord.
          DiscordUtil.login(planDuration, planType);
        }
        break;
      case "supporter":
        if (state?.sessionId) {
          navigate(`/yearly/supporter/${state.sessionId}`);
        } else {
          navigate(`/supporter`);
        }
        break;
    }
  }

  return (
    <>
      <UspPremium></UspPremium>

      <div className="row d-flex justify-content-center mb-10">
        <div className="col-auto">
          <DurationSwitcher
            planDuration={planDuration}
            onPlanDurationChange={setPlanDuration}
          />
        </div>
      </div>

      <div className="row mb-10">
        <div className="col-md-4 gy-3 gy-md-0">
          <div className="card card-stretch border-secondary">
            <div className="card-body">
              <PlanHeader planType="free" planDuration={planDuration} />
              <FeatureList planType="free" />
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button
                  onClick={() => onGetPlan("free")}
                  className="btn btn-lg btn-dark fw-bolder fs-3 btn-hover-scale"
                >
                  Invite the bot
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 gy-3 gy-md-0">
          <div className="card card-stretch border-primary">
            <div className="card-body">
              <PlanHeader planType="premium" planDuration={planDuration} />
              <FeatureList planType="premium" />
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button
                  onClick={() => onGetPlan("premium")}
                  className={`btn btn-lg btn-primary fw-bolder fs-5 btn-hover-scale`}
                >
                  Get the <span className="fs-3">Premium</span> plan
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 gy-3 gy-md-0">
          <div className="card card-stretch border-sbot">
            <div className="card-body">
              <PlanHeader planType="premiumPlus" planDuration={planDuration} />
              <FeatureList planType="premiumPlus" />
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button
                  onClick={() => onGetPlan("premiumPlus")}
                  className={`btn btn-lg btn-sbot fw-bolder fs-5 btn-hover-scale`}
                >
                  Get the <span className="fs-3">Premium Plus</span> plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center mb-10">
        <div className="col-md-12">
          <div className="card border-success">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 my-auto">
                  <PlanHeader planType="supporter" planDuration="yearly" />
                </div>
                <div className="col-md-4">
                  <FeatureList planType="supporter" />
                </div>
                <div className="col-md-4 text-center mt-4 my-md-auto">
                  <button
                    onClick={() => onGetPlan("supporter")}
                    className="btn btn-success btn-lg fw-bolder fs-5 btn-hover-scale"
                  >
                    Learn more about becoming a<br/><span className="fs-3">Supporter</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans;
