import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DurationSwitcher from "../components/DurationSwitcher";
import PlanHeader from "../components/PlanHeader";
import UspPremium from "../components/UspPremium";
import { useGuildsQuery } from "../hooks/useMemberServiceApi";
import { APP_ENV, STATIC_CONFIG } from "../utils/config";
import { PlanDuration, PlanType } from "../utils/config-types";
import { PaddlePassthrough, PaddleUtil } from "../utils/paddle-util";
import {
  PaddleSubscriptionPlans,
  PaddleSubscriptionPlansConfig,
} from "../utils/plans";

function Plan() {
  const { planDurationParam, planTypeParam, sessionId } = useParams();
  const [planDuration, setPlanDuration] = useState<PlanDuration>(
    planDurationParam! as PlanDuration
  );
  const [planType, setPlanType] = useState<PlanType>(
    planTypeParam! as PlanType
  );
  const [selectedGuildId, setSelectedGuildId] = useState<string>();
  const [selectedGuildName, setSelectedGuildName] = useState<string>();
  const navigate = useNavigate();

  const planTypeConfig = PaddleSubscriptionPlansConfig[planType!];
  const otherPlanTypeConfig =
    PaddleSubscriptionPlansConfig[planTypeConfig.otherType as PlanType];
  const planInfo = PaddleSubscriptionPlans[planType!][planDuration!][APP_ENV];

  function switchToOtherPlanType() {
    navigate(`/${planDuration}/${planTypeConfig.otherType}/${sessionId}`);
    setPlanType(planTypeConfig.otherType as PlanType);
  }

  function navToAllPlans() {
    navigate("/plans", {
      state: { planDuration: planDuration, sessionId: sessionId },
    });
  }

  function navToSuccess() {
    navigate("/success", {
      replace: true,
      state: {
        planDuration: planDuration,
        planType: planType,
        serverName: selectedGuildName,
      },
    });
  }

  function onGetPlan() {
    let passthrough: PaddlePassthrough = {
      discordGuildId: selectedGuildId!,
      userId: guilds!.userId,
    };
    //console.log(`get the ${planDuration} plan, ${planType}, for '${selectedGuildName}' (${selectedGuildId}).
    // ${JSON.stringify(passthrough)}`);

    PaddleUtil.openCheckout(
      planInfo.planNumber,
      guilds!.email,
      passthrough,
      navToSuccess
    );
  }

  function onSelectGuild(id: string, name: string) {
    setSelectedGuildId(id);
    setSelectedGuildName(name);
  }

  const { isLoading, isError, data: guilds } = useGuildsQuery(sessionId);

  if (isError) {
    return <p>Error!</p>;
  }
  if (isLoading || !guilds) {
    return <p>Loading...</p>;
  }
  if (guilds.guilds.length < 1) {
    return (
      <>
        <p>Looks like you don't belong to any Discord servers.</p>
        <p>
          Try joining a server first, then try{" "}
          <a href={STATIC_CONFIG.SITE_PREMIUM}>purchasing again.</a>
        </p>
      </>
    );
  }

  return (
    <>
      <UspPremium></UspPremium>

      <div className="row d-flex justify-content-center mb-10">
        <div className="col-auto">
          <DurationSwitcher
            planDuration={planDuration}
            onPlanDurationChange={setPlanDuration}
          />
        </div>
      </div>

      <div className="row g-4 justify-content-center mb-10">
        <div className="col-md-6">
          <div className={`card ${planTypeConfig.borderClass}`}>
            <div className="card-body">
              <PlanHeader planType={planType!} planDuration={planDuration} />

              <ul className="list-group list-group-flush">
                <li className="list-group-item bg-secondary p-7">
                  <h3 className="text-center pb-3">
                    Select the server where you want to use
                    <br />
                    Reaction Roles <em>{planTypeConfig.title}</em>
                  </h3>
                  <select
                    onChange={(e) =>
                      onSelectGuild(
                        e.target.value,
                        e.target.selectedOptions[0].label
                      )
                    }
                    className="form-select"
                    aria-label="Select Server"
                    defaultValue="no-selection"
                  >
                    <option
                      key="no-selection"
                      disabled
                      value="no-selection"
                      style={{ display: "none" }}
                    >
                      {" "}
                      -- Please select a server --
                    </option>
                    {guilds.guilds.map((g) => {
                      return (
                        <option key={g.id} value={g.id}>
                          {g.name}
                        </option>
                      );
                    })}
                  </select>
                </li>
              </ul>
            </div>
            <div className="card-footer">
              <div className="d-grid">
                <button
                  onClick={onGetPlan}
                  disabled={!selectedGuildId}
                  className={`btn btn-lg ${planTypeConfig.buttonClass} fw-bolder fs-5 btn-hover-scale`}
                >
                  Get the <span className="fs-3">{planTypeConfig.title}</span>{" "}
                  plan
                </button>
              </div>
            </div>
          </div>

          <div className="text-center mt-8">
            <button
              className="btn btn-sm btn-light fw-bolder fs-8 me-5"
              onClick={navToAllPlans}
            >
              View All Plans
            </button>
            <button
              className={`btn btn-sm ${otherPlanTypeConfig.buttonClass} fw-bolder fs-8`}
              onClick={switchToOtherPlanType}
            >
              Go to {otherPlanTypeConfig.title} plan
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plan;
